html, body {
  min-height: 100%;
  height: 1px;
}

* {
  box-sizing: border-box;
}

#root {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100%;
  height: 1px;
}
